import React, { createContext, useContext, useState, useEffect } from 'react';
import Cookie from 'js-cookie';
import mtLinkSdk from '@moneytree/mt-link-javascript-sdk';
import { APP_SETTINGS } from 'services/App/App.constants';
import { useHealthStatus } from './healthStatus';

const envVarMap = {
  DEVELOPMENT: 'staging',
  STAGING: 'staging',
  PRODUCTION: 'production'
};

mtLinkSdk.init(APP_SETTINGS.CLIENT_ID, {
  mode: envVarMap[process.env.REACT_APP_STAGE_ENVIRONMENT] || 'develop',
  backTo: `${window.location.origin}/callback`,
  redirectUri: `${window.location.origin}/callback`
});

const defaultValue = {
  accessToken: undefined,
  accessTokenInfo: undefined
};

const loginRetryFlagKey = 'loginRetryFlag';

const AuthContext = createContext(defaultValue);

export function useAuth() {
  return useContext(AuthContext);
}

function cleanUpState() {
  localStorage.removeItem('imageCache');
  localStorage.removeItem('imageDegreeCache');
  Cookie.remove('expiresAt');
  Cookie.remove('accessToken');
  Cookie.remove('omoikaneUrl');
}

export function login() {
  let currentPath = window.location.pathname;

  if (window.location.search) {
    currentPath += window.location.search;
  }

  mtLinkSdk.authorize({
    pkce: true,
    state: JSON.stringify({ path: currentPath }),
    scopes: ['guest_read', 'subscription']
  });
}

export function signup() {
  mtLinkSdk.authorize({
    authAction: 'signup',
    forceLogout: true,
    pkce: true,
    state: JSON.stringify({ path: '/' }),
    scopes: ['guest_read', 'subscription']
  });
}

export function logout(skipSdkLogout) {
  cleanUpState();

  if (!skipSdkLogout) {
    mtLinkSdk.logout();
  }
}

export function myAccountSettings() {
  mtLinkSdk.openService('myaccount');
}

export default function(props) {
  const { children } = props;
  const [authInfo, setAuthInfo] = useState(defaultValue);
  const { accessToken, accessTokenInfo } = authInfo;
  const { setStatus } = useHealthStatus();

  useEffect(() => {
    // do nothing if we already have an access token,
    // we will have an access token when the following code managed to get one and set it into the state
    // which in turn re-trigger this effect
    if (accessToken) {
      return;
    }

    // we always start from fresh on reload since we don't store token on the browser side
    // only in memory
    cleanUpState();

    // exchange code for token if we are at a redirection callback state
    if (
      window.location.pathname === '/callback' &&
      window.location.href.indexOf('code') !== -1
    ) {
      mtLinkSdk
        .exchangeToken()
        .then(newToken => {
          // validate token
          mtLinkSdk.tokenInfo(newToken).then(newTokenInfo => {
            // set token information into cookie
            const expiresDateTimeInMs = newTokenInfo.exp * 1000;

            const options = {
              secure: true,
              expires: new Date(expiresDateTimeInMs)
            };

            Cookie.set('expiresAt', expiresDateTimeInMs, options);
            Cookie.set('accessToken', newToken, options);
            Cookie.set('omoikaneUrl', newTokenInfo.aud[0], options);

            setAuthInfo({
              accessToken: newToken,
              accessTokenInfo: newTokenInfo
            });
          });

          sessionStorage.removeItem(loginRetryFlagKey);
        })
        .catch(error => {
          console.error('Unable to retrieve an access token.', error);

          const hasLoginBeenRetried = !!sessionStorage.getItem(loginRetryFlagKey);

          if (hasLoginBeenRetried) {
            sessionStorage.removeItem(loginRetryFlagKey);
            setStatus({ error: true });
          } else {
            sessionStorage.setItem(loginRetryFlagKey, "true");
            login();
          }
        });
      return;
    }

    // authorize for a new token,
    // this will trigger redirection that land to callback state in above above
    if (window.location.pathname === '/signup') {
      signup();
    } else {
      login();
    }
  }, [accessToken, setAuthInfo, setStatus]);

  return (
    <AuthContext.Provider value={{ accessToken, accessTokenInfo }}>
      {children}
    </AuthContext.Provider>
  );
}
