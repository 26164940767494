/* eslint-disable global-require,no-underscore-dangle */

const ENV_APP_SETTINGS = {
  PRODUCTION: {
    CLIENT_ID: '2f5c4a5f8b5db8a2a85109645ca8fafcfddd7975ef12d615c99d94ea7efce7df',
    VERSION: '6'
  },
  STAGING: {
    CLIENT_ID: '1b374ffc7d08132517da3c3cf635889cf172ca2da6db3e8a01c87fa349fe88bf',
    VERSION: '6'
  },
  DEVELOPMENT: {
    CLIENT_ID: '1b374ffc7d08132517da3c3cf635889cf172ca2da6db3e8a01c87fa349fe88bf',
    VERSION: '6'
  }
};

// Sign up, and authentication should always hit the same place.
const ENV_AUTH_API_SETTINGS = {
  PRODUCTION: {
    JP: {
      API_BASE_URL: 'https://jp-api.getmoneytree.com'
    },
    myAccount: 'https://myaccount.getmoneytree.com'
  },
  STAGING: {
    JP: {
      API_BASE_URL: 'https://jp-api-staging.getmoneytree.com'
    },
    myAccount: 'https://myaccount-staging.getmoneytree.com'
  },
  DEVELOPMENT: {
    JP: {
      API_BASE_URL: 'https://jp-api-staging.getmoneytree.com'
    },
    myAccount: 'https://myaccount-staging.getmoneytree.com'
  }
};

const ENV_CLOUD_SAFE_SETTINGS = {
  PRODUCTION: {
    rotateImageFunction: 'MtGuestUploads-production-imageRotate'
  },
  STAGING: {
    rotateImageFunction: 'MtGuestUploads-staging-imageRotate'
  },
  DEVELOPMENT: {
    rotateImageFunction: 'MtGuestUploads-staging-imageRotate'
  }
};

const ENV_MIXPANEL_SETTINGS = {
  PRODUCTION: {
    token: 'b64530e65b5c9457f336725b2ab83f90',
    debug: false
  },
  STAGING: {
    token: '02a5ace72fa8a7af71e2432381bf7af5',
    debug: false
  },
  DEVELOPMENT: {
    token: '02a5ace72fa8a7af71e2432381bf7af5',
    debug: true
  }
};

const ENV_STRIPE_SETTINGS = {
  DEVELOPMENT: {
    PUBLISHABLE_KEY: 'pk_test_RkNGMqGmPUV4NzvVooIHx56j',
    TABLE_ID: {
      plus_en: 'prctbl_1NvJK7Ag6kp2VxKfGYcFfwUh',
      plus_ja: 'prctbl_1NuXmpAg6kp2VxKfJYZgXEgc',
      pro_en: 'prctbl_1NvJElAg6kp2VxKfSlJ3jy34',
      pro_ja: 'prctbl_1NuY5cAg6kp2VxKfadV30OxD'
    }
  },
  STAGING: {
    PUBLISHABLE_KEY: 'pk_test_RkNGMqGmPUV4NzvVooIHx56j',
    TABLE_ID: {
      plus_en: 'prctbl_1NvJK7Ag6kp2VxKfGYcFfwUh',
      plus_ja: 'prctbl_1NuXmpAg6kp2VxKfJYZgXEgc',
      pro_en: 'prctbl_1NvJElAg6kp2VxKfSlJ3jy34',
      pro_ja: 'prctbl_1NuY5cAg6kp2VxKfadV30OxD'
    }
  },
  PRODUCTION: {
    PUBLISHABLE_KEY: 'pk_live_DzjHI3tZ6cVzQjbg7QsCepR5',
    TABLE_ID: {
      plus_en: 'prctbl_1Nwc25Ag6kp2VxKf7y4k6Oay',
      plus_ja: 'prctbl_1Nwc5EAg6kp2VxKfPzgiHzPS',
      pro_en: 'prctbl_1Nwc7ZAg6kp2VxKfUbLXDER6',
      pro_ja: 'prctbl_1Nwc9lAg6kp2VxKfnIjP3A9Q'
    }
  }
};

const APP_SETTINGS = ENV_APP_SETTINGS[process.env.REACT_APP_STAGE_ENVIRONMENT];
const AUTH_API_SETTINGS = ENV_AUTH_API_SETTINGS[process.env.REACT_APP_STAGE_ENVIRONMENT];
const CLOUD_SAFE_SETTINGS = ENV_CLOUD_SAFE_SETTINGS[process.env.REACT_APP_STAGE_ENVIRONMENT];
const MIXPANEL_SETTINGS = ENV_MIXPANEL_SETTINGS[process.env.REACT_APP_STAGE_ENVIRONMENT];
const STRIPE_SETTINGS = ENV_STRIPE_SETTINGS[process.env.REACT_APP_STAGE_ENVIRONMENT];

const SUBSCRIPTIONS_MAP = {
  work: 'premium',
  corporate: 'professional',
  grow: 'starter'
};

export { APP_SETTINGS, AUTH_API_SETTINGS, CLOUD_SAFE_SETTINGS, MIXPANEL_SETTINGS, STRIPE_SETTINGS, SUBSCRIPTIONS_MAP };
